import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "bootstrap/dist/css/bootstrap.min.css";

const Skills = ({location}) => (
  <Layout location={location}>
    <SEO title="スキル" />
    <table class="skillsTable">
      <tr style={{fontSize: "200%"}}>
        <th></th>
        <th>★★★</th>
        <th>★★☆</th>
        <th>★☆☆</th>
      </tr>
      <tr>
        <th>プログラミング言語</th>
        <td>C++, C#, HLSL, GLSL, HTML, CSS, PHP, JavaScript, Typescript</td>
        <td>Lua, Python, Java</td>
        <td>MEL, Assembly</td>
      </tr>
      <tr>
        <th>プラットフォーム</th>
        <td>Windows</td>
        <td>Wii, Xbox 360, PlayStation 3</td>
        <td>Linux</td>
      </tr>
      <tr>
        <th>ツール</th>
        <td>Visual Studio, VSCode, Maya, 3ds Max, Photoshop, Gamebryo, Unity 3D</td>
        <td>
          Blender, PIX, Eclipse, Node.js, SVN, git
        </td>
        <td>Unreal Engine, Eclipse, Perforce</td>
      </tr>
      <tr>
        <th>ライブラリ</th>
        <td>OpenGL, WebGL, DirectX, .NET, WPF, SDL</td>
        <td>Vulkan, OpenAL, FreeType, Ogg Vorbis, Dear ImGui</td>
        <td>Win32, Qt, Angular, Maya C++ API</td>
      </tr>
    </table>

    <div>&nbsp;</div>

    <table class="skillsTable">
      <tr>
        <td colspan="2">人間言語</td>
      </tr>
      <tr>
        <th>英語</th>
        <td>ネイティブ</td>
      </tr>
      <tr>
        <th>日本語</th>
        <td>中級 (日本語能力試験N３級に合格した）</td>
      </tr>
    </table>
  </Layout>
);

export default Skills;
